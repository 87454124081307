export function Controller(ProcessesData, context, gettextCatalog) {
  'ngInject'

  let $ctrl = this

  $ctrl.started = false

  $ctrl.model = {
    includeFiles: false
  }

  $ctrl.formFields = [
    {
      template: '<p translate>Should uploaded files be included?</p>'
    },
    {
      key: 'includeFiles',
      type: 'checkbox',
      templateOptions: {
        label: gettextCatalog.getString('Yes, include uploaded files')
      }
    },
    {
      template:
        '<p translate>Warning: This may take some time (up to 3 days) and produce a large export file. If you choose to include uploaded files you will receive the export via email. </p>'
      // hideExpression: function ($viewValue, $modelValue, scope) {
      //   return !scope.model.includeFiles
      // }
    }
  ]

  $ctrl.$onInit = function () {}

  $ctrl.runExport = () => {
    $ctrl.started = true

    ProcessesData.initAccountExport(context.get('account').id, $ctrl.model.includeFiles).then(function () {
      $ctrl.dismiss({ $value: 'started' })
    })
  }
}
